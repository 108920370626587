timesheetFilters = {}

# dom ready
$ ->
  # prevent double submit
  $('form').submit ->
    for element in $('input[type=submit]')
      element.classList.add('disabled')
    return true

  # toggle drop-down rows in tables
  $('tr.pri').click ->
    $(this).next().toggleClass 'collapse'
    $(this.getElementsByClassName('glyphicon')[0]).toggleClass('glyphicon-chevron-down')
  # put focus on input group
  $('.input-group > input').focus ->
    $(this).parent().addClass('input-group-focus')
  .blur ->
      $(this).parent().removeClass('input-group-focus')

  # Add reason to login form.
  reasonIdx = location.href.indexOf('reason=')
  if reasonIdx > -1
    $('<p>' + decodeURIComponent(location.href.substring(reasonIdx + 7)).replace('<', '') + '</p>').prependTo($('.form-signin'))

  # Navbar collapsable.
  navbarCollapsable = $('.navbar-collapse')
  $('.navbar-toggle').click(->
    if navbarCollapsable.css('display') isnt 'block'
      props =
        display: 'block'
        visibility: 'visible'
    else
      props =
        display: ''
        visibility: ''
    navbarCollapsable.css(props)
  )

  $('.timesheet .timeframe.new .field-start_time input, .timesheet .timeframe.new .field-end_time input').focusout(doFocusOutTime)

  return

doFocusOutTime = (e) ->
  inputControl = $(e.target)
  closestTimeframe = inputControl.closest('.timeframe')
  startTimeValue = closestTimeframe.find('.field-start_time input').val()
  endTimeValue = closestTimeframe.find('.field-end_time input').val()

  if closestTimeframe.hasClass('new') and startTimeValue and endTimeValue
    timesheetId = inputControl.closest('.timesheet').data('timesheet-id')
    closestTimeframe.removeClass('new')

    # Get new form id
    form = $('#timesheet-' + timesheetId + ' #id_form-TOTAL_FORMS')
    formIdx = form.val()

    # Generate new row
    newRowHtml = $('#timesheet-' + timesheetId + ' .empty_form').html().replace(/__prefix__/g, formIdx)

    # Append in DOM
    $(newRowHtml).insertAfter(closestTimeframe)

    # Set initial values of new form fields
    dateValue = closestTimeframe.find('.field-date input').val()
    isBreakValue = closestTimeframe.find('.field-is_break input').val()
    $('#timesheet-' + timesheetId + ' #id_form-' + formIdx + '-timesheet_id').val(timesheetId)
    $('#timesheet-' + timesheetId + ' #id_form-' + formIdx + '-date').val(dateValue)
    $('#timesheet-' + timesheetId + ' #id_form-' + formIdx + '-is_break').val(isBreakValue)

    $('#timesheet-' + timesheetId + ' #id_form-' + formIdx + '-end_time').focusout(doFocusOutTime)
    $('#timesheet-' + timesheetId + ' #id_form-' + formIdx + '-start_time').focusout(doFocusOutTime).focus()

    #Update form counter
    form.val parseInt(formIdx) + 1

  return

clearInputRow = (element) ->
  $(element).closest('.timeframe').find('.field-start_time input').val('')
  $(element).closest('.timeframe').find('.field-end_time input').val('')
  $(element).closest('.timeframe').find('.field-next_day')[0].classList.add('hidden')
  return

copyTimesheet = (id, confirmMessage, failMessage) ->
  response = window.confirm(confirmMessage)

  if response
    $.ajax({
      method: 'POST',
      url: id + '/copy/',
      headers: { 'X-CSRFToken': getCookie('csrftoken') },
      success: ->
        window.location.reload()
        return
      error: ->
        window.alert(failMessage)
        return
    })
  return

showTimeframes = (organizationId) ->
  organizationId = parseInt(organizationId)
  if organizationId > 0
    $('.organization-timesheet').hide()
    $('.organization-' + organizationId).show()
    $('.organization-timesheet-' + organizationId).show()
    $('.organization-item').removeClass('selected')
    $('#organization-' + organizationId).addClass('selected')
    $('.organization-item .selection-indicator').removeClass('active-selection')
    $('#organization-' + organizationId + ' .selection-indicator').addClass('active-selection')
    sessionStorage.setItem('selected-organization', organizationId)
  return

filterTimesheets = ->
  filters = $('div.timesheet-filters').find('select')

  for filter in filters
    index = filter.selectedIndex
    if index >= 0
      timesheetFilters[filter.name] = filter.options[index].value

  window.location.search = getFilterQueryString()
  return

switchWorker = (url) ->
  window.location.href = url + window.location.search
  return

getFilterQueryString = ->
  queryString = ''
  params = {}

  for FilterKey, FilterValue of timesheetFilters
    params['filter_' + FilterKey] = FilterValue

  if Object.keys(params).length > 0
    queryString = '?' + $.param(params)

  return queryString
